import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { JsonReaderService } from '../json-reader/json-reader.service';
import { combineLatest, filter } from 'rxjs';
import { ArticleService } from '../articles/article.service';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private meta: Meta,
    private titleService: Title,
    private jsonReaderService: JsonReaderService,
    private articlesService: ArticleService,
    @Inject(DOCUMENT) private doc: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.registerNavigationEvents();
  }

  defaultTitle = 'Albion Free Market';
  defaultDescription = 'Albion Online Market Tools.';
  defaultRobots = 'all';
  defaultImage = `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`;

  registerNavigationEvents() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        var rt = this.getChild(this.activatedRoute);

        combineLatest([rt.paramMap, rt.data, this.jsonReaderService.usNameMappingsHasLoaded$, this.articlesService.articlesHaveLoaded$])
          .pipe(filter(([params, data, namesLoaded, articlesLoaded]) => namesLoaded && articlesLoaded))
          .subscribe(([params, data, namesLoaded, articlesLoaded]) => {
            if (params.keys.length > 0 && !!params.keys[0]) {
              const key = params.keys[0];
              const value = params.get(key);

              if (key === 'uniqueName') {
                let usName = value;
                if (value) {
                  usName = this.jsonReaderService.usNameMappings[value];
                  // console.log(`Found usName: ${usName}`);
                }

                if (data['title']) {
                  data['title'] = data['title'].replace(`:${key}`, usName);
                  // console.log(`Changed title data to: ${data['title']}`);
                }
                if (data['description']) {
                  data['description'] = data['description'].replace(`:${key}`, usName);
                  // console.log(`Changed description data to: ${data['description']}`);
                }
                if (data['canonical']) {
                  data['canonical'] = data['canonical'].replace(`:${key}`, value);
                  // console.log(`Changed canonical data to: ${data['canonical']}`);
                }
              }

              if (data['canonical'].includes('/articles/view')) {
                let id = '';

                if (key === 'id') {
                  if (value) {
                    id = value;
                    // console.log(`Found id: ${id}`);
                  }
                }

                const articleData = this.articlesService.getArticleInstant(id);

                if (articleData) {
                  if (data['title']) {
                    data['title'] = data['title'].replace(`:${key}`, articleData.title);
                    // console.log(`Changed title data to: ${data['title']}`);
                  }
                  if (data['description']) {
                    data['description'] = data['description'].replace(`:${key}`, articleData.summary);
                    // console.log(`Changed description data to: ${data['description']}`);
                  }
                  if (data['canonical']) {
                    data['canonical'] = data['canonical'].replace(`:${key}`, value);
                    // console.log(`Changed canonical data to: ${data['canonical']}`);
                  }
                }
              }
            }

            // TWITTER
            this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
            this.meta.updateTag({ name: 'twitter:site', content: '@albionfreemark' });

            // OG:TYPE
            this.meta.updateTag({ property: 'og:type', content: 'website' });

            // TITLE
            // console.log(`Found title data: ${this.titleService.getTitle()}`);
            this.titleService.setTitle(data['title'] || this.defaultTitle);
            // console.log(`Title updated to: ${this.titleService.getTitle()}`);
            this.meta.updateTag({
              property: 'og:title',
              content: data['title'] || this.defaultTitle,
            });

            // DESCRIPTION
            // console.log(`Found description data: ${this.meta.getTag('name=description')?.content}`);
            this.meta.updateTag({
              name: 'description',
              content: data['description'] || this.defaultDescription,
            });
            // console.log(`Description updated to: ${this.meta.getTag('name=description')?.content}`);
            this.meta.updateTag({
              property: 'og:description',
              content: data['description'] || this.defaultDescription,
            });

            // ROBOTS
            this.meta.updateTag({
              name: 'robots',
              content: data['robots'] || this.defaultRobots,
            });

            // IMAGE
            this.meta.updateTag({
              property: 'og:image',
              content: data['image'] || this.defaultImage,
            });

            // URL AND CANONICAL
            const linkElement = this.doc.querySelector('link[rel="canonical"]') as HTMLLinkElement;
            if (data['canonical']) {
              this.meta.updateTag({
                property: 'og:url',
                content: data['canonical'],
              });
              if (linkElement) {
                // console.log(`Canonical URL found: ${linkElement.href}`);
                linkElement.href = data['canonical'];
                // console.log(`Canonical URL updated to: ${data['canonical']} from existing element`);
              } else {
                const newLinkElement = this.doc.createElement('link');
                newLinkElement.rel = 'canonical';
                newLinkElement.href = data['canonical'];
                this.doc.head.appendChild(newLinkElement);
                // console.log(`Canonical URL updated to: ${data['canonical']} from new element`);
              }
            } else {
              if (linkElement) {
                // console.log(`Canonical URL found: ${linkElement.href}`);
                linkElement.remove();
                // console.log(`Canonical URL removed`);
              }
            }
          });
      }
    });
  }

  getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  // private updateTitle(routerState: RouterStateSnapshot): void {
  //   let title = routerState.root.firstChild?.title ?? 'No Title Page';

  //   if (routerState.url.includes('articles/view')) {
  //     this.articleService.getArticle(routerState.root.firstChild?.paramMap.get('id') ?? '').subscribe((article) => {
  //       if (article) {
  //         title = article.title;
  //       }
  //       this.titleService.setTitle(title);
  //       if (isPlatformBrowser(this.platformId)) {
  //         this.analyticsService.sendCustomPageView(title);
  //       }
  //     });
  //   } else {
  //     let param = routerState.root.firstChild?.paramMap.get('uniqueName') ?? '';
  //     if (param) {
  //       const readableName = this.jsonReaderService.usNameMappings?.[param] ?? param;
  //       title = `${title} - ${readableName}`;
  //     }
  //     this.titleService.setTitle(title);
  //     if (isPlatformBrowser(this.platformId)) {
  //       this.analyticsService.sendCustomPageView(title);
  //     }
  //   }
  // }
}
