import { inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SvgIconsService {
  constructor() {
    const iconRegistry = inject(MatIconRegistry);
    const sanitizer = inject(DomSanitizer);

    iconRegistry.addSvgIcon('discord', sanitizer.bypassSecurityTrustResourceUrl('../../assets/img/svg-icons/discord.svg'));
    iconRegistry.addSvgIcon('youtube', sanitizer.bypassSecurityTrustResourceUrl('../../assets/img/svg-icons/youtube.svg'));
    iconRegistry.addSvgIcon('twitch', sanitizer.bypassSecurityTrustResourceUrl('../../assets/img/svg-icons/twitch.svg'));
    iconRegistry.addSvgIcon('ssl', sanitizer.bypassSecurityTrustResourceUrl('../../assets/img/svg-icons/ssl.svg'));
    iconRegistry.addSvgIcon('lock', sanitizer.bypassSecurityTrustResourceUrl('../../assets/img/svg-icons/lock.svg'));
    iconRegistry.addSvgIcon('usd', sanitizer.bypassSecurityTrustResourceUrl('../../assets/img/svg-icons/usd.svg'));
    iconRegistry.addSvgIcon('brl', sanitizer.bypassSecurityTrustResourceUrl('../../assets/img/svg-icons/brl.svg'));
  }
}
