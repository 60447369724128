import { ApplicationConfig, importProvidersFrom, Injector, provideZoneChangeDetection, SecurityContext } from '@angular/core';
import { RouterModule, provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { HttpClient, provideHttpClient, withFetch } from '@angular/common/http';

import { DecimalPipe, isPlatformServer } from '@angular/common';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import {
  AppCheck,
  initializeAppCheck,
  provideAppCheck,
  ReCaptchaEnterpriseProvider,
} from '@angular/fire/app-check';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { SmallNumberPipe } from './pipes/small-number/small-number.pipe';
import { provideMarkdown } from 'ngx-markdown';
import { provideClientHydration, withEventReplay, withNoHttpTransferCache } from '@angular/platform-browser';

import { PLATFORM_ID, inject } from '@angular/core';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'albionfreemarket.com',
  },
  position: 'bottom',
  theme: 'block',
  palette: {
    popup: {
      background: '#000000',
      text: '#ffffff',
      link: '#ffffff',
    },
    button: {
      background: '#f1d600',
      text: '#000000',
      border: 'transparent',
    },
  },
  type: 'opt-out',
  content: {
    message: 'This website uses cookies to ensure you get the best experience on our website.',
    dismiss: 'Got it!',
    deny: 'Only Analytics',
    link: 'Learn more',
    href: 'https://albionfreemarket.com/privacy-policy',
    policy: 'Cookie Policy',
  },
  animateRevokable: true,
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    importProvidersFrom(RouterModule.forRoot(routes), BrowserAnimationsModule),
    provideRouter(routes),
    provideClientHydration(withEventReplay(), withNoHttpTransferCache()),
    provideAnimations(),
    provideHttpClient(withFetch()),
    provideMarkdown({ loader: HttpClient, sanitize: SecurityContext.NONE }),
    [DecimalPipe, SmallNumberPipe],
    provideFirebaseApp(() =>
      initializeApp({
        projectId: 'albionfreemarket',
        appId: '1:615549188968:web:e5735956dd1193073774dd',
        storageBucket: 'albionfreemarket.appspot.com',
        apiKey: 'AIzaSyCvbK52ZEuUAVSK7tQBb-UhXJdjLU11gEQ',
        authDomain: 'albionfreemarket.firebaseapp.com',
        messagingSenderId: '615549188968',
        measurementId: 'G-16BBPNQCEY',
      })
    ),
    // {
    //   provide: AppCheck,
    //   deps: [PLATFORM_ID],
    //   useFactory: (platformId: Object) => {
    //     if (isPlatformBrowser(platformId)) {
    //       const app = getApp();
    //       return initializeAppCheck(app, {
    //         provider: new ReCaptchaEnterpriseProvider('6LcUbi4pAAAAAMFYfbRQAEWnhLmu33-L4tRYtGD9'),
    //         isTokenAutoRefreshEnabled: true,
    //       });
    //     }
    //     return null;
    //   },
    // },
    // provideAppCheck((injector: Injector): AppCheck => {
    //   const platformId = inject(PLATFORM_ID);

    //   // For server-side rendering, return a mock AppCheck instance
    //   if (isPlatformServer(platformId)) {
    //     return {
    //       // Minimal mock implementation of AppCheck
    //       app: getApp(),
    //       // Add other required AppCheck properties as needed
    //     } as AppCheck;
    //   }

    //   // Enable debug token in development
    //   if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
    //     // @ts-ignore
    //     self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
    //   }

    //   try {
    //     const provider = new ReCaptchaEnterpriseProvider('6LcUbi4pAAAAAMFYfbRQAEWnhLmu33-L4tRYtGD9');

    //     return initializeAppCheck(getApp(), {
    //       provider,
    //       isTokenAutoRefreshEnabled: true,
    //     });
    //   } catch (error) {
    //     console.error('Failed to initialize AppCheck:', error);
    //     // Throw error instead of returning null to satisfy type constraint
    //     throw new Error('AppCheck initialization failed');
    //   }
    // }),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    // provideNgcCookieConsent(cookieConfig),
    MatSnackBar,
  ],
};
