import { AfterViewInit, Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { MainPageComponent } from './shared/components/main-page/main-page.component';
import { UserSettingsService } from './services/user-settings/user-settings.service';
import { Router } from '@angular/router';
import { SeoService } from './services/seo-service/seo.service';
import { SvgIconsService } from './services/svg-icons.service';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Component({
    selector: 'app-root',
    imports: [MainPageComponent],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  // private popupOpenSubscription!: Subscription;
  // private popupCloseSubscription!: Subscription;
  // private initializingSubscription!: Subscription;
  // private initializedSubscription!: Subscription;
  // private initializationErrorSubscription!: Subscription;
  // private statusChangeSubscription!: Subscription;
  // private revokeChoiceSubscription!: Subscription;
  // private noCookieLawSubscription!: Subscription;

  constructor(
    private userSettingsService: UserSettingsService,
    svgIconService: SvgIconsService,
    // private ccService: NgcCookieConsentService,
    private router: Router,
    // @Inject(DOCUMENT) private document: Document,
    private seoService: SeoService
  ) {}
  @HostBinding('class') public cssClass!: string;

  ngOnInit(): void {
    this.userSettingsService.userTheme$.subscribe((theme: string) => {
      this.cssClass = theme;
    });

    // // subscribe to cookieconsent observables to react to main events
    // this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
    //   // you can use this.ccService.getConfig() to do stuff...      console.log(`initialized: ${JSON.stringify(event)}`);
    // });

    // this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
    //   // you can use this.ccService.getConfig() to do stuff...
    // });

    // this.initializingSubscription = this.ccService.initializing$.subscribe((event: NgcInitializingEvent) => {
    //   // the cookieconsent is initilializing... Not yet safe to call methods like `NgcCookieConsentService.hasAnswered()`
    // });

    // this.initializedSubscription = this.ccService.initialized$.subscribe(() => {
    //   // the cookieconsent has been successfully initialized.
    //   // It's now safe to use methods on NgcCookieConsentService that require it, like `hasAnswered()` for eg...
    // });

    // this.initializationErrorSubscription = this.ccService.initializationError$.subscribe((event: NgcInitializationErrorEvent) => {
    //   // the cookieconsent has failed to initialize...
    // });

    // this.statusChangeSubscription = this.ccService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {
    //   if (event.status == 'allow') {
    //     this.changeConsent('allow');
    //   } else if (event.status == 'deny') {
    //     this.changeConsent('deny');
    //   } else if (event.status == 'dismiss') {
    //     this.ccService.clearStatus();
    //     this.changeConsent('dismiss');
    //   }

    //   if (this.ccService.isOpen()) {
    //     this.ccService.close(false);
    //   }
    //   this.ccService.toggleRevokeButton(false);
    // });

    // this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(() => {
    //   this.changeConsent('deny');
    // });

    // this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe((event: NgcNoCookieLawEvent) => {
    //   // you can use this.ccService.getConfig() to do stuff...
    // });
  }

  ngAfterViewInit() {
    // if (this.ccService.hasConsented()) {
    //   this.changeConsent('allow');
    //   if (this.ccService.isOpen()) {
    //     this.ccService.close(false);
    //   }
    //   this.ccService.toggleRevokeButton(false);
    // }
  }
  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    // this.popupOpenSubscription.unsubscribe();
    // this.popupCloseSubscription.unsubscribe();
    // this.initializingSubscription.unsubscribe();
    // this.initializedSubscription.unsubscribe();
    // this.initializationErrorSubscription.unsubscribe();
    // this.statusChangeSubscription.unsubscribe();
    // this.revokeChoiceSubscription.unsubscribe();
    // this.noCookieLawSubscription.unsubscribe();
  }
  title = 'Albion Free Market';

  // private changeConsent(status: string): void {
  //   window.dataLayer = window.dataLayer || [];
  //   function gtag(...args: any[]): void {
  //     window.dataLayer.push(arguments);
  //   }
  //   let consentData;
  //   if (status == 'allow') {
  //     consentData = {
  //       ad_storage: 'granted',
  //       ad_user_data: 'granted',
  //       ad_personalization: 'granted',
  //       analytics_storage: 'granted',
  //     };
  //   } else if (status == 'deny') {
  //     consentData = {
  //       ad_personalization: 'denied',
  //       ad_user_data: 'denied',
  //       ad_storage: 'denied',
  //       analytics_storage: 'granted',
  //     };
  //   } else if (status == 'dismiss') {
  //     consentData = {
  //       ad_personalization: 'denied',
  //       ad_user_data: 'denied',
  //       ad_storage: 'denied',
  //       analytics_storage: 'granted',
  //     };
  //   }
  //   gtag('consent', 'update', consentData);

  //   // Store consent data as a cookie
  //   let date = new Date();
  //   date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000); // 365 days in milliseconds
  //   let expires = 'expires=' + date.toUTCString();
  //   document.cookie = `consentData=${JSON.stringify(consentData)}; path=/; ${expires}`;
  // }

  // private getConsentData(): any {
  //   let name = 'consentData=';
  //   let decodedCookie = decodeURIComponent(document.cookie);
  //   let ca = decodedCookie.split(';');
  //   for (let i = 0; i < ca.length; i++) {
  //     let c = ca[i];
  //     while (c.charAt(0) == ' ') {
  //       c = c.substring(1);
  //     }
  //     if (c.indexOf(name) == 0) {
  //       return JSON.parse(c.substring(name.length, c.length));
  //     }
  //   }
  //   return {};
  // }
}
